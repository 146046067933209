.error-animation {
    animation: pulse-error 2s infinite;
  }
  
  @keyframes pulse-error {
    0% {
      border-color: red;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: red;
    }
  }
  